import React from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
// import Info from "./Info";
import Projects from "./Projects";
import ProjectCard from "./ProjectCard";
import Construction from "./Construction";
import './Base.css';
import uniqid from "uniqid"
// import waldo from './media/waldo.png'
import weather from './media/weather.png'
import synth_home from './media/synth_home.gif'
import synth_info from './media/sythn_mod.png'
import synth_vca from './media/synth_vca.gif'
import synth_osc from './media/synth_vco.gif'
import synth_fm from './media/synth_fm.gif'
import synth_quiz from './media/synth_quiz.gif'
import cam_hold from './media/cam_brush_hold_menu.png'
import cam_result_1 from './media/cam_brush_result_1.png'
import cam_result_2 from './media/cam_brush_result_2.png'

const Base = () => {
  const projects = [
  //   {
  //   'title': 'Where\'s Waldo',
  //   'year': '2023',
  //   'url': 'waldo',
  //   'body': (
  //     <>
  //     <div style={{zIndex: 1}}><a className="project-demo" href="https://where-s-waldo-d5b83.web.app/" target="_blank" rel="noreferrer noopener">Where's Waldo</a> is an interactive game which presents the user with a large photograph from which they must find and identify several characters (Waldo, Odlaw, Wizard, and Wilma). Implementation required frontend functionality akin to a photo tagging app. The user's selection is converted to image coordinates so as to be viewport-agnostic.</div> 
  //     <div>This project gave me a chance to work with a backend. The functions for verifying the user's selection are run on the server-side so it can't be manipulated. Additionally, the time it takes for the user to find all the characters is recorded on the backend and stored on a leader board.</div>
  //     <img className="project-img" src={waldo} alt="Where's Waldo Demo"></img>
  //     <div className="keywords">Keywords: Firebase, React (props, hooks, and routes), APIs</div>
  //     <div><a href="https://github.com" target="_blank" rel="noreferrer noopener">GitHub</a></div>
  //     </>
  //     ),
  //   id: uniqid()
  // },
  {
    'title': 'Weather App',
    'year': '2022',
    'url': 'weather',
    'body': (
      <>
      <div style={{zIndex: 1}}><a className="project-demo" href="https://." target="_blank" rel="noreferrer noopener">Weather App</a> is an exercise in working with API calls and asynchronus JavaScript. Using the WeatherAPI the user can search a specific location in order and toggle the weather results between Fahrenheit or Celsius.</div> 
      <img className="project-img" src={weather} alt="Weather App Demo"></img>
      <div className="keywords">Keywords: Asynchronus JavaScript, APIs</div>
      <div><a href="https://github.com" target="_blank" rel="noreferrer noopener">GitHub</a></div>
      </>
      ),
    id: uniqid()
  },{
    'title': 'Modular Synthesizer Tutorial',
    'year': '2021',
    'url': 'synth',
    'body': (
      <>
      <div style={{zIndex: 1}}><a className="project-demo" href="https://jimmyhughes.pythonanywhere.com/" target="_blank" rel="noreferrer noopener">Modular Synthesizer Tutorial</a> is an interactive interface designed for teaching the basics of modular synthesis. It is meant to be a tutorial so the user can interact with it at their own pace. Each page has audio and visual components the user can interact with.</div> 
      <div>Below are samples of each page. The tutorial is designed to be completed in the order presented below but there is also navigation functionality if the user wants to skip ahead or go back. Obviously there is no sound component in these samples so please try on your own computer to get the full experience!</div>
      {/* <div>Home</div> */}
      <img className="project-img" src={synth_home} alt="Home Page"></img>
      {/* <div>Modular Synth Info</div> */}
      <img className="project-img" src={synth_info} alt="Info Page"></img>
      {/* <div>Voltage Control Amplifier</div> */}
      <img className="project-img" src={synth_vca} alt="VCA Page"></img>
      {/* <div>Oscillator</div> */}
      <img className="project-img" src={synth_osc} alt="Oscillator Page"></img>
      {/* <div>Frequency Modulation</div> */}
      <img className="project-img" src={synth_fm} alt="FM Page"></img>
      {/* <div>Quiz</div>  */}
      <img className="project-img" src={synth_quiz} alt="Quiz Page"></img>
      <div className="keywords">Keywords: Flask, p5, JQuery, Bootstrap, NexusUI</div>
      <div><a href="https://github.com/jimmy-hughes/synth-site/tree/master" target="_blank" rel="noreferrer noopener">GitHub</a></div>
      </>
      ),
    id: uniqid()
  },{
    'title': 'Cam Brush',
    'year': '2021',
    'url': 'cam-brush',
    'body': (
      <>
      <div style={{zIndex: 1}}><a className="project-demo" href="https://github.com/jimmy-hughes/cam-brush" target="_blank" rel="noreferrer noopener">Cam Brush</a> allows the user to paint in 3D space, much like Tilt Brush, by using their webcam and a spherical object such as a lollipop.</div> 
      <div>Full documentation can be found in the repository READ.ME and <a href="https://github.com/jimmy-hughes/cam-brush/blob/main/docs/report.pdf" target="_blank" rel="noreferrer noopener">this writeup</a>.</div>
      <img className="project-img" src={cam_hold} alt="Cam Brush GUI"></img>
      <img className="project-img" src={cam_result_1} alt="Cam Brush Results"></img>
      <img className="project-img" src={cam_result_2} alt="Cam Brush Results"></img>
      <div className="keywords">Keywords: Python, OpenCV</div>
      <div><a href="https://github.com/jimmy-hughes/cam-brush" target="_blank" rel="noreferrer noopener">GitHub</a></div>
      </>
      ),
    id: uniqid()
  },{
    'title': 'Utilizing Acoustic Features for Topic Recognition',
    'year': '2021',
    'url': 'topic-recognition',
    'body': (
      <>
      <div style={{zIndex: 1}}><a className="project-demo" href="https://github.com/jimmy-hughes/topic-recognition-research" target="_blank" rel="noreferrer noopener">Utilizing Acoustic Features for Topic Recognition on TED-LIUM corpus</a> is a study I conducted to answer the question: can the topic of a TED Talk be determined by using only acoustic information? Topic classification is frequently used as a text-mining tool for the discovery of hidden semantic structures in large collections of text, documents and recordings. Typically these models rely solely on information from the text but it is possible there is information hidden in the dynamics of the suprasegmental features. The purpose of this study will be to determine if any advantage can be leveraged from the acoustic information of the speech to develop a more accurate topic identification model.</div> 
      <div>Writeup and scripts can be found <a href="https://github.com/jimmy-hughes/topic-recognition-research" target="_blank" rel="noreferrer noopener">here</a>.</div>
      <div className="keywords">Keywords: Machine Learning, Linux, sklearn, TDNN</div>
      </>
      ),
    id: uniqid()
  },];

  return (
    <BrowserRouter>
      <div id="body-container">
        <div id="left-container">
          <div id="tagline">
            <Link to="/">
              <div>
                <div>Jimmy Hughes</div>
                <div>Full Stack</div>
                <div>Baltimore, MD</div>
              </div>
            </Link>
            <svg id="bottom-left-curve" viewBox="0 0 100 100">
              <path
                d="M 0 100 V 0 H 100 C 40 0 0 40 0 100"
              ></path>
            </svg>
            <svg id="top-right-curve" viewBox="0 0 100 100">
              <path
                d="M 0 100 V 0 H 100 C 40 0 0 40 0 100"
              ></path>
            </svg>
          </div>
          <div id="menu">
            <div className="menu-item"><Link to="/">Info</Link></div>
            <div className="menu-item"><Link to="/projects">Projects</Link></div>
          </div>
        </div>

        <div id="right-container">
          <Routes>
            <Route path="/" element={<Construction />} />
            {/* <Route path="/" element={<Info />} /> */}
            <Route path="/projects" element={<Projects projects={projects}/>} />
            {projects.map((project) =>
              <Route path={"/projects/"+project.url} key={project.id} element={<ProjectCard project={project}/>} />
            )}
            {/* <Route path="*" element={<>You have landed on a page that doesn't exist</>} /> */}
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
};

export default Base;