import React, {useState, useEffect, useRef} from "react";

function ProjectCard(props) {
  const {project} = props;
  const [modalVis, setShowModal] = useState("hidden");  // modal visiblity
  const [leftVis, setLeftVis] = useState("hidden");     // left arrow visiblity 
  const [rightVis, setRightVis] = useState("hidden");   // right arrow visiblity
  const [modalSrc, setModalSrc] = useState({});         // image to display in modal
  const [touchStart, setTouchStart] = useState(null);   // touch start coordinate (swipe functionality)
  const [touchEnd, setTouchEnd] = useState(null);       // touch end coordinate (swipe functionality)
  const modalRef = useRef(null);                        // modal DOM node ref 
  const minSwipeDistance = 50;                          // required distance for detectible swipe

  // function for setting modal source and showing modal
  const openModal = (img, i) => {
    setShowModal("visible");
    setModalSrc({
      'src': img.src,
      'alt': img.alt,
      'index': i
    });
  };
  // function for hiding modal
  const closeModal = () => {
    setShowModal("hidden");
    setLeftVis("hidden");
    setRightVis("hidden");
  };

  // hooks for updating modal visibility
  useEffect(() => {
    let imageNodes = document.getElementsByClassName('project-img'); // array of images in DOM
    var closeDiv = document.getElementById("close");                 // close modal DOM node

    // function for determining if click was outside the modal DOM ref
    function handleClickOutsideModal(event) {
      if (modalRef.current && !modalRef.current.contains(event.target) && !event.target.classList.contains("arrow")) {
        closeModal();
      }
    }

    // event listeners on images for opening modal
    for (var i=0; i<imageNodes.length; i++)
    {          
      let idx = i;
      imageNodes[i].addEventListener('click', function() {
        openModal(this, idx);
      });
    }
    // event listener on modal close button
    closeDiv.addEventListener('click', function() {closeModal()});
    document.addEventListener("mousedown", handleClickOutsideModal);

    return () => {
      // unbind event listeners on clean up
      for (var i=0; i<imageNodes.length; i++)
      {
        let idx = i;        
        imageNodes[i].removeEventListener('click', function() {
          openModal(this, idx);
        });
      }
      closeDiv.removeEventListener('click', function() {closeModal()});
      document.removeEventListener("mousedown", handleClickOutsideModal);
    };
  }, []);

  // hooks for arrow controls
  useEffect(() => {
    let imageNodes = document.getElementsByClassName('project-img');
    var leftArrow = document.getElementById("left-arrow");
    var rightArrow = document.getElementById("right-arrow");
    let modalImg = document.getElementsByClassName("modal-img")[0];

    function clickLeft() {
      let curIdx = modalSrc.index;
      if (curIdx > 0) {
        let newImg = imageNodes[curIdx - 1];
        setModalSrc({
          'src': newImg.src,
          'alt': newImg.alt,
          'index': curIdx - 1
        });
      }
    }

    function clickRight() {
      let curIdx = modalSrc.index;
      if (curIdx < imageNodes.length - 1) {
        let newImg = imageNodes[curIdx + 1];
        setModalSrc({
          'src': newImg.src,
          'alt': newImg.alt,
          'index': curIdx + 1
        });
      }
    }

    function handleKeyDown(event) {
      switch(event.keyCode) {
        case 37:
          clickLeft();
          break;
        case 39:
          clickRight();
          break;
        default:
          break;
      }
    }

      // functions for swipe functionality
    const onTouchStart = (e) => {
      setTouchEnd(null);
      setTouchStart(e.targetTouches[0].clientX);
    };

    const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);
    
    const onTouchEnd = () => {
      if (!touchStart || !touchEnd) return
      const distance = touchStart - touchEnd
      if (distance > minSwipeDistance) {
        clickRight();
      }
      else if (distance < -minSwipeDistance) {
        clickLeft();
      }
    }

    leftArrow.addEventListener("click", clickLeft);
    rightArrow.addEventListener("click", clickRight)
    document.addEventListener("keydown", handleKeyDown);
    modalImg.addEventListener("touchstart", onTouchStart);
    modalImg.addEventListener("touchmove", onTouchMove);
    modalImg.addEventListener("touchend", onTouchEnd);

    return () => {
      leftArrow.removeEventListener('click', clickLeft);
      rightArrow.removeEventListener('click', clickRight);
      document.removeEventListener("keydown", handleKeyDown);
      modalImg.removeEventListener("touchstart", onTouchStart);
      modalImg.removeEventListener("touchmove", onTouchMove);
      modalImg.removeEventListener("touchend", onTouchEnd);
    };
  }, [modalSrc, touchEnd, touchStart]);

  // hooks for updating arrow visibility
  useEffect(() => {
    let imageNodes = document.getElementsByClassName('project-img');

    let curIdx = modalSrc.index;
    if (curIdx === 0 || modalVis === "hidden") {
      setLeftVis('hidden');
    } else {
      setLeftVis('visible');
    }
    if (curIdx === imageNodes.length - 1 || modalVis === "hidden") {
      setRightVis('hidden');
    } else {
      setRightVis('visible');
    }
  }, [modalVis, modalSrc])

  return (
    <>
      {project.body}
      <div id="modal" style={{visibility: modalVis}}>
        <div className="modal-content">
          <svg id="close" viewBox="0 0 180 180">
            <path d="M5 5 L175 175 M175 5 L5 175"></path>
          </svg>
          <div className="modal-img-container">
              <svg id="left-arrow" className="arrow" viewBox="0 0 100 100" style={{visibility: leftVis}}>
                <polyline points="100,100 40,50 100,0" fill="none"></polyline>							
              </svg>
              <img className="modal-img" src={modalSrc['src']} alt={modalSrc['alt']} ref={modalRef}></img>
              <svg id="right-arrow" className="arrow" viewBox="0 0 100 100" style={{visibility: rightVis}}>
                <polyline points="0,100 60,50 0,0" fill="none"></polyline>							
              </svg>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProjectCard;