
function Construction() {
  return (
    <>
      <div>
        𓉴 Construction in progress 𓉴
        
      </div>
    </>
  );
}

export default Construction;
